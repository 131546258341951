import jfwSchedule from "./com.jacksonfamilywines.Schedule";
import PortWineFestBanner from "./com.portwinefest.PortWineFestBanner";
import PortWineFestImageGalleryPebbleBeach from "./com.portwinefest.PortWineFestImageGalleryPebbleBeach";
import PortWineFestImageGalleryVail from "./com.portwinefest.PortWineFestImageGalleryVail";

export default {
  "com.jacksonfamilywines.Schedule": jfwSchedule,
  "com.portwinefest.PortWineFestImageGalleryPebbleBeach":
    PortWineFestImageGalleryPebbleBeach,
  "com.portwinefest.PortWineFestBanner": PortWineFestBanner,
  "com.portwinefest.PortWineFestImageGalleryVail": PortWineFestImageGalleryVail,
};
